import React from "react";
import './menu.css'
function Menu() {
  const copyReferral = () => {
    const referralInput = document.getElementById("referralInput");
    referralInput.select();
    referralInput.setSelectionRange(0, 99999); // Для мобільних пристроїв
    document.execCommand("copy");
  };

  return (
    <>
      <div class="message">
        Энергия восстанавливается! Пока ждёшь, приглашай друзей.
      </div>
      <div class="referral-container">
        <div class="referral-text">
          Твоя ссылка для приглашения:
        </div>
        <input
          type="text"
          id="referralInput"
          value={`https://t.me/wow_stars_bot?start=${localStorage.getItem('referral')}`}
          readonly
          className="referral-text-input"
        />
        <button className="copy-button" id="referralInput" onClick={copyReferral}>Скопировать</button>
        <div class="additional-message">
          Отправь её друзьям. За каждого - даем <span class="star-bonus">1.9 ⭐</span>
        </div>
      </div>
    </>
  );
}

export default Menu
