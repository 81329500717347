import React, { useState, useEffect, useRef } from "react";
import "./final_bread_tap.css";
import Accordion from "./Accordion";
import { Link } from "react-router-dom";
import { getTelegramUser, updateTapCounter } from '../api/api';
import dataRewards from '../constans';
import Tasks from "./Tas";
import Timer from "./Timer"; // Імпортуємо компонент Timer
import FooterNav from './FooterNav'
import Topbar from "./TopBar";
import Menu from "./Menu";

const BreadTapApp = () => {
  const faqData = [
    {
      question: "Что такое Bread Tap?",
      answer: "Это игра, где каждый клик приближает тебя к вершине рейтинга!",
    },
    {
      question: "Как улучшить рейтинг?",
      answer: "Нажимай на хлеб, зарабатывай очки и поднимайся в таблице лидеров!",
    }
  ];
  const [tasks, setTasks] = useState([]);
  const [isTasksModalOpen, setIsTasksModalOpen] = useState(false);
  const [user, setUser] = useState({});
  const [score, setScore] = useState(0);
  const [energy, setEnergy] = useState(0);

  const [progress, setProgress] = useState(0);
  const [rewardMessage, setRewardMessage] = useState("");
  const [activeReferrals, setActiveReferrals] = useState(0);
  const [referrals, setReferrals] = useState(0);
  const [userRank, setUserRank] = useState(null);
  const scoreRef = useRef(0);
  const energyScore = useRef(0);

  const [totalTaps, setTotalTaps] = useState(0);
  const rewardMessages = dataRewards;
  const [clicked, setClicked] = useState(false);
  const [isTimerVisible, setIsTimerVisible] = useState(false); // Новий стан для відображення таймера
  const [timeRemaining, setTimeRemaining] = useState(0); // Ваш час у секундах

  useEffect(() => {
    fetchUserData();
  }, []);

  const openTasksModal = (newTasks) => {
    setTasks(newTasks);
    setIsTasksModalOpen(true);
  };

  const fetchUserData = async () => {
    try {
      if (window.Telegram.WebApp) {
        window.Telegram.WebApp.ready();
        window.Telegram.WebApp.expand();
        const userData = window.Telegram.WebApp;
        const user = userData.initDataUnsafe.user;
        setUser(user);
        const data = await getTelegramUser(user.id).catch((err) => alert('Что-то не так с сервером. Мы уже чиним. Админ на связи.'));
        localStorage.setItem('referral', data.user.referralLink);
        localStorage.setItem('tapCounter', data.user.tapCounter);
        localStorage.setItem('userId', user.id);
        localStorage.setItem('userPhoto', user.photo_url);
        localStorage.setItem('username', user.first_name || user.username);

        localStorage.setItem('user', user.id);
        localStorage.setItem('referrals', data.referrals.toString());
        localStorage.setItem('activeReferrals', data.activeReferrals.toString());

        localStorage.setItem('totalCounter', data.user.totalCounter);

        setUserRank(data.userPosition);
        setTotalTaps(data.user.totalCounter);
        setReferrals(data.referrals.toString());
        energyScore.current = data.user.energy
        setScore(data.user.tapCounter);
        setEnergy(Math.round(data.user.energy))
        scoreRef.current = data.user.tapCounter;
        setActiveReferrals(data.activeReferrals.toString());
        if (data.user.energy <= 0) {
          time(data.user.updated)
        }
      }

      return;
    } catch (error) {
      console.error("Помилка завантаження даних користувача:", error);
    }
  };

  const time = async (time) => {
    const lastUpdateDate = new Date(time); // Замените на вашу дату
    // Текущая дата
    const currentDate = new Date();
    // Добавляем 15 минут к дате последнего обновления
    const targetDate = new Date(lastUpdateDate.getTime() + (15 * 60000));
    // Вычисляем разницу в миллисекундах
    const timeDifference = targetDate - currentDate;
    // Конвертируем разницу в минуты
    const minutesRemaining = Math.max(Math.floor(timeDifference / (1000)), 0); // Используем Math.max, чтобы избежать отрицательных значений

    setTimeRemaining(minutesRemaining)
    setTimeout(() => { setIsTimerVisible(false) }, minutesRemaining * 1000)
    setIsTimerVisible(true)
  }

  const updateUserTapCounter = async () => {
    if (user) {
      try {
        if (scoreRef <= 0) {
          time(user.updated)
          return;
        }
        return updateTapCounter(user.id, scoreRef.current).catch((err) => alert('Что-то не так с сервером. Мы уже чиним. Админ на связи.'));
      } catch (error) {
        console.error("Помилка при відправці даних про тапи:", error);
      }
    }
  };

  const handleBreadClick = async (event) => {
    setClicked(true);
    setTimeout(() => setClicked(false), 200);

    // if (energyScore.current <= 0) {
    //   return
    // }

    const animation = document.createElement("div");
    animation.classList.add("animation");
    animation.textContent = "+0.285 ⭐️";

    // Позиціювання анімації
    const rect = event.target.getBoundingClientRect();
    animation.style.left = `${event.clientX - rect.left - 10}px`;
    animation.style.top = `${event.clientY - rect.top - 10}px`;
    animation.style.pointerEvents = "none";
    // Додавання анімації до DOM
    const starContainer = event.target.closest(".star-container");
    starContainer.appendChild(animation);

    // Видалення анімації після завершення
    setTimeout(() => {
      animation.remove();
    }, 1000);


    scoreRef.current += 0.285;
    scoreRef.current = Math.round(scoreRef.current * 1000) / 1000;
    const newScore = scoreRef.current;
    setScore(newScore);
    setProgress((newScore % 50) * 2);

    localStorage.setItem('tapCounter', scoreRef.current);

    if (energyScore.current > 0) {
      energyScore.current -= 1
      const newEnergy = energyScore.current
      setEnergy(Math.round(newEnergy))
    }

    if (newScore % 50 === 0) {
      triggerReward();
    }

    updateUserTapCounter().then(res => {

      if (res?.tasks?.length > 0) {
        openTasksModal(res.tasks);
      }
      if (res?.timer) {
        scoreRef.current -= 1
        energyScore.current = res.energy || 0
        setScore(scoreRef.current)
        setEnergy(Math.round(energyScore.current))

        return time(res.timer)
      }
    })
    // Показуємо таймер після кліка
    //setIsTimerVisible(true);
  };

  const triggerReward = () => {
    const randomMessage = rewardMessages[Math.floor(Math.random() * (rewardMessages.length - 1))];
    setRewardMessage(randomMessage);
    setTimeout(() => setRewardMessage(""), 2000);

    createFirework();
  };

  const createFirework = () => {
    const firework = document.createElement("div");
    firework.className = "firework";
    firework.style.left = `${Math.random() * 90}%`;
    firework.style.top = `${Math.random() * 50}%`;
    document.getElementById("fireworks-container").appendChild(firework);

    setTimeout(() => {
      firework.remove();
    }, 1000);
  };

  return (
    <div className="container">
      <Topbar />


      <div className="content">
        <p id="score-display" className="score-display">
          {score === 0 ? 0 : score.toFixed(3)}
        </p>
        {isTimerVisible ? (
          <Menu /> // Відображаємо компоненту Timer при кліку
        ) : (
          <>
            <div className="star-container" onClick={handleBreadClick}>
              <div className="star" ></div>
            </div>
            <div className="progress-bar-container">
              <div
                id="progress-bar"
                className="progress-bar"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </>
        )}

        <p id="reward-message" className="reward-message">
          {rewardMessage || ''}
        </p>
        <div id="fireworks-container" className="fireworks-container"></div>

        {isTasksModalOpen && (
          <Tasks
            tasks={tasks}
            onClose={() => setIsTasksModalOpen(false)}
          />
        )}
      </div>
      <p className="energy-p">
        Энергия 🗲<span className="user-rank">{energy.toString() || "Загрузка..."}</span>
      </p>
      <FooterNav />
    </div>
  );
};

export default BreadTapApp;
